export const services = [
  "Removal",
  "House Renovation",
  "Carpet & Flooring",
  "Painting",
  "Damage Repair",
  "Electricity & Gas",
  "Lock Smith",
  "Solar Panels",
  "Window & Heating & Ventilation",
  "Car",
];
